import React from 'react'
import Seo from '../components/seo'
import Layout from '../components/layout'

// Utilities

// Components
import { Helmet } from 'react-helmet'

const TagsPage = () => (
  <div>
    <Layout>
      <div style={{ background: '#fff' }} className="blog">
        <Seo
          title="Blog | Adam Doe"
          description="nothin"
          //pathname={pageData.slug}
          article
        />
        <Helmet />
        <div>
          <h1>Tags</h1>
        </div>
      </div>
    </Layout>
  </div>
)

export default TagsPage
